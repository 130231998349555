<template>
	<v-container class="pa-0 ma-0" fluid>
		<v-row class="pa-0 ma-0">
			<v-col cols="12">
				<Datatable
					:api-endpoint="ENDPOINTS.DATATABLES.ERP.STORAGEDETAILS"
					:data="this.params"
					:datatable-headers="datatableHeaders"
					:excel-columns="excelColumns"
					:excel-file-name="
						(this.storageName && storageName.toLowerCase().trim().replace(' ', '')) || 'storagedetails'
					"
					:v-show="true"
					ref="storageDetailsDatatable"
				/>
			</v-col>
			<v-col cols="12">
				<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
					<vue-touch-keyboard
						:accept="hideTouchKeyboard"
						:cancel="hideTouchKeyboard"
						:defaultKeySet="touchKeyboard.keySet"
						:input="touchKeyboard.input"
						:layout="touchKeyboard.layout"
						:options="touchKeyboard.options"
						class="internalWidthExpanded"
						id="onScreenKeyboard"
						v-if="touchKeyboard.visible"
					/>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
//configs
import { ENDPOINTS } from "../../../../config";
import mixin from "../../../../mixins/KeyboardMixIns";
//components
import Datatable from "../../../datatable/Datatable";
import { Events } from "@/plugins/events";

export default {
	components: {
		Datatable,
	},
	props: {
		storageName: String,
	},
	mixins: [mixin],
	data: () => {
		return {
			ENDPOINTS: ENDPOINTS,
		};
	},
	computed: {
		params() {
			return {
				storageID: this.$route.params.id,
			};
		},
		datatableHeaders: function () {
			return [
				{
					text: "ID",
					align: "left",
					value: "id",
					hide: true,
				},
				{
					text: this.$t("erp.lang_ware_create_id"),
					value: "ean",
					sortable: false,
				},
				{
					text: this.$t("erp.lang_posItemName"),
					value: "item_name",
					sortable: false,
				},
        {
          text: this.$t("erp.lang_ware_cat"),
          value: "item_group",
          sortable: false,
        },
        {
          text: this.$t("erp.lang_ware_producer_name"),
          value: "item_producer",
          sortable: false,
        },
				{
					text: this.$t("erp.lang_QTY"),
					value: "quantity",
				},
				{
					text: this.$t("erp.lang_ek_net_short"),
					value: "purchase_price_net",
				},
				{
					text: this.$t("erp.lang_vk_netto"),
					value: "sell_price_net",
				},
				{
					text: this.$t("erp.lang_vk_brutto"),
					value: "gross_sell_price",
				},
				{
					text: this.$t("erp.lang_storagePlace"),
					value: "storageShelfLocation",
				},
			];
		},
		excelColumns: function () {
			return [
				{
					label: "ID",
					field: "id",
				},
				{
					label: this.$t("erp.lang_ware_create_id"),
					field: "ean",
				},
				{
					label: this.$t("erp.lang_posItemName"),
					field: "item_name",
				},
				{
					label: this.$t("erp.lang_QTY"),
					field: "quantity",
				},
				{
					label: this.$t("erp.lang_ek_net_short"),
					field: "purchase_price_net",
				},
				{
					label: this.$t("erp.lang_vk_netto"),
					field: "sell_price_net",
				},
				{
					label: this.$t("erp.lang_vk_brutto"),
					field: "gross_sell_price",
				},
				{
					text: this.$t("erp.lang_storagePlace"),
					field: "storageShelfLocation",
				},
			];
		},
	},
	mounted() {
		this.loadStorage();
	},
	methods: {
		async loadStorage() {
			//this.$store.commit("loader/setLoader", true);
			await this.axios
				.post(ENDPOINTS.ERP.STORAGEOVERVIEW.SHOW, {
					storageID: this.$route.params.id,
				})
				.then((result) => {
					this.$emit("input", result.data.storageInformation.storageName);
				})
				.catch((err) => {
					//this.$store.commit("loader/setLoader", false);
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred"),
						color: "error",
					});
				});
		},
	},
};
</script>
